<template>
  <div class="preview">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <div class="course-title">
            <div>
              <div class="name">{{ problemDetail.title }}</div>
              <div class="detailed">
                <span>题目提供者：{{ problemDetail.user_real_name }}</span>
                <span>难度：{{ problemDetail.diff_title }}</span>
                <span>提交：{{problemDetail.log_num}}</span>
                <span>及格率：{{problemDetail.jige_pre}}</span>
                <span>时长：{{
                    problemDetail.duration == 0
                      ? "无限制"
                      : problemDetail.duration + "s"
                  }}</span>
              </div>
            </div>
            <!-- <div class="edit">
              <span>时长：</span>
              <el-input
                v-model="duration"
                placeholder="请输入内容"
                size="small"
                style="width: 50%"
              ></el-input>
              <div class="bgGreen_btn">编辑</div>
            </div> -->
          </div>

          <!-- //题目列表 -->
          <TopicSum v-loading="loading" :detailList="problemDetail" :deploy="{isAnswer:true}" :show_answer="true"></TopicSum>

          <div>
            <div class="button-primary" :style="{ float:'right',padding:'4px 20px'}" @click="confirm">确定</div>
          </div>
        </div>
        <div class="content-right">
          <TopicList @topicClick="topicClick" :topicParam="topicParam" :pitchNums="pitchNums"></TopicList>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100" :right="400" :visibility-height="400">
      <div class="go-top">
        <i class="el-icon-caret-top" style="color: #00957e"></i>
        <div style="margin-top: -8px;">顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue"
import TopicSum from "@/components/TopicSum/TopicSum.vue"
import { detailList, collection, bisaidetail, bisaianswer } from "@/api/problemList.js"
export default {
  components: {
    TopicList,
    TopicSum
  },
  data () {
    return {
      loading: false,
      duration: "",
      id: "",
      problemDetail: {},
      topicParam: {
        topicList: [],
        knowledgeTag: [],
      },
      //题目排序分类
      topicTypeList: {
      },
      pitchNums: []
    }
  },
  computed: {
    optionsData () {
      return (v) => {
        return {
          topicData: v,
          isAnswer: true
        }
      }
    }
  },
  created () {
    this.id = this.$route.query.id
    this.loading = true
    detailList({ id: this.id }).then((res) => {
      this.problemDetail = res.data
      this.pitchNums = res.data.questions.map(v => v.id)
      this.topicParam = {
        topicList: [
          ...res.data.questions
        ],
        knowledgeTag: res.data.know_format,
      }
      this.loading = false
    })
  },
  methods: {
    //滚动到点击的题目
    topicClick (selector) {
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    },
    confirm () {
      this.$router.push({ path: "/problemList" })
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/topic.less";
::v-deep {
  .el-backtop {
    width: 56px;
    height: 56px;
  }
}
.go-top {
  .el-icon-caret-top {
    font-size: 26px;
    margin-top: -6px;
  }
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 70%;
    font-size: 14px;
    color: #2f2f2f;

    .course-title {
      padding: 2rem;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #dbdbdb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .edit {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .name {
        font-size: 21px;
        color: #0a0a0a;
        line-height: 29px;
        margin-bottom: 1rem;
      }
      .detailed {
        font-size: 14px;
        color: #0a0a0a;
        line-height: 20px;
        > span {
          margin-right: 1.5rem;
        }
      }
    }
  }
  .content-right {
    width: 27%;
    .margin {
      margin-top: 40px;
    }
  }
}
</style>
